import { useState } from 'react';
import { Button, FormControl, FormLabel, Input, VStack, useToast, Text } from '@chakra-ui/react';
import { useAppointmentActions } from '../../hooks';
import { SoftCard } from '../generic/SoftCard';
import { useRecoilValue } from 'recoil';
import { currentUserState } from '../../state';

export const CreateAppointments = ({ handleUpdate }: { handleUpdate: Function }) => {
  // const roundToNextEvenHourSimple = (date: Date) => {
  //   const hours = date.getHours();
  //   date.setMinutes(0, 0, 0); // Reset minutes, seconds, and milliseconds
  //   date.setHours(hours + 1);
  //   return date;
  // };

  const getNextEvenHour = () => {
    const now = new Date();
    now.setMinutes(0, 0, 0); // Reset minutes, seconds, and milliseconds

    const nextEvenHour = now.getHours() % 2 === 0 ? now.getHours() + 2 : now.getHours() + 1;
    now.setHours(nextEvenHour);

    return now.toISOString().slice(0, 16); // Extract only YYYY-MM-DDTHH:MM part
  };

  const initialStartTime = getNextEvenHour();
  const initialEndTime = new Date(new Date(initialStartTime).getTime() + 4 * 60 * 60 * 1000).toISOString().slice(0, 16); // Add 2 hours for end time

  const [rawStartTime, setRawStartTime] = useState(initialStartTime);
  const [rawEndTime, setRawEndTime] = useState(initialEndTime);

  const { createAppointments } = useAppointmentActions();

  const user = useRecoilValue(currentUserState);

  const toast = useToast();

  const handleSubmit = async () => {
    try {
      if (new Date(rawEndTime) <= new Date(rawStartTime)) {
        toast({
          title: 'Felaktig tid',
          description: 'Sluttiden bör vara efter starttiden',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      if (user) {
        await createAppointments({
          therapistId: user.id,
          startTime: new Date(rawStartTime),
          endTime: new Date(rawEndTime),
          intervalMinutes: 30,
        });
      }
      await handleUpdate();
      toast({
        title: 'Tidsslottar skapade',
        status: 'success',
        duration: 1500,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Något gick fel',
        description: 'Något gick fel',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={4}>
      <Text>Tillgänglighet</Text>
      <SoftCard p={8}>
        <VStack spacing={4}>
          <FormControl>
            <FormLabel>Arbetspassets start</FormLabel>
            <Input
              type="datetime-local"
              value={rawStartTime}
              onChange={(e) => {
                if (e.target.value) {
                  setRawStartTime(e.target.value);
                }
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Arbetspassets slut</FormLabel>
            <Input
              type="datetime-local"
              value={rawEndTime}
              onChange={(e) => {
                if (e.target.value) {
                  setRawEndTime(e.target.value);
                }
              }}
            />
          </FormControl>
          {/* <FormControl>
          <FormLabel>Interval (minutes)</FormLabel>
          <Input type="number" value={intervalMinutes} onChange={(e) => setIntervalMinutes(e.target.value)} />
        </FormControl> */}
          <Button colorScheme="purple" onClick={handleSubmit}>
            Skapa bokningsbara tidsslottar
          </Button>
        </VStack>
      </SoftCard>
    </VStack>
  );
};

export default CreateAppointments;

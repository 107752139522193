import React, { useEffect, useState } from 'react';
import { Alert, AlertDescription, AlertIcon, Box, Checkbox, FormLabel, Image, Switch, Text } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';

import { Appointment, Appointments, Therapist } from '../types';
import { AuthenticatedLayout } from '../layouts';
import { useTherapistActions } from '../hooks/therapist-actions';
import { useAppointmentActions } from '../hooks';
import { AppointmentList } from '../components/appointments/AppointmentList';
import { ModalDefault } from '../components/generic/ModalDefault';
import {
  calculateMinutesBetweenDates,
  formatDateStringWithWeekdayInSwedish,
  formatTimeString,
} from '../helpers/dateFormatting';
import { usePaymentActions } from '../hooks/payment-actions';
import { SoftCard } from '../components/generic/SoftCard';

const TherapistDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const { getTherapistById } = useTherapistActions();
  const { getAppointmentsByTherapistId, previewAppointmentById, previewCouplesAppointmentById } =
    useAppointmentActions();

  const { purchaseProduct } = usePaymentActions();

  const [freeCard, setFreeCard] = useState(false);
  const [isBooked, setIsBooked] = useState(false);
  const [therapist, setTherapist] = useState<Therapist | null>(null);
  const [appointments, setAppointments] = useState<Appointments>([]);
  const [previewedAppointment, setPreviewedAppointment] = useState<Appointment | null>(null);

  const [showCouples, setShowCouples] = useState(false);
  const [nextAppointmentId, setNextAppointmentId] = useState<string | null>(null);

  const doIt = async () => {
    if (id) {
      const fetchedTherapist = await getTherapistById(id);
      const fetchedAppointments = await getAppointmentsByTherapistId(id);
      if (fetchedTherapist) {
        setTherapist(fetchedTherapist);
      }

      if (fetchedAppointments) {
        setAppointments(fetchedAppointments);
        console.log(fetchedAppointments);
      }
    }
  };

  const preview = async (appointmentIds: string[]) => {
    // await bookAppointment(appointmentId);
    let res;
    if (showCouples) {
      setNextAppointmentId(appointmentIds[1]);
      res = await previewCouplesAppointmentById(appointmentIds[0], appointmentIds[1]);
    } else {
      res = await previewAppointmentById(appointmentIds[0]);
    }
    if (res) {
      setPreviewedAppointment(res);
      // doIt();
    }
  };

  useEffect(() => {
    doIt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const priceText =
    freeCard && showCouples
      ? 'Pris 200kr. Denna avgift är för din partner. Din partner måste visa upp sin legitimation under mötet, samt uppge ev frikort.'
      : !freeCard && showCouples
      ? 'Pris: 400kr. Priset anger kostnaden för dig och din partner. Din partner måste visa upp sin legitimation under mötet, samt uppge ev frikort.'
      : freeCard && !showCouples
      ? 'Pris: 0kr.'
      : 'Pris: 200kr. Högkostnadsskydd gäller.';

  const book = async (appointmentId: string) => {
    setIsBooked(false);
    console.log(showCouples, appointmentId, nextAppointmentId);
    const appointmentToBook = await previewAppointmentById(appointmentId);
    if (appointmentToBook && appointmentToBook.status !== 'available') {
      setIsBooked(true);
      setPreviewedAppointment(null);
      doIt();
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return;
    }
    await purchaseProduct(showCouples, appointmentId, nextAppointmentId, freeCard);
    setPreviewedAppointment(null);
    setFreeCard(false);
    navigate('/');
  };

  return (
    <AuthenticatedLayout sidebar={false}>
      {isBooked && (
        <Alert rounded={8} status="error">
          <AlertIcon />
          <AlertDescription>
            Den tiden du har valt har tyvärr redan blivit bokad av någon annan. Välj en ny tid.
          </AlertDescription>
        </Alert>
      )}
      {therapist && (
        <SoftCard>
          <Box display={{ md: 'flex' }}>
            <Image
              width={'screen'}
              display={{ md: 'none' }}
              objectFit="cover"
              borderRadius={{ base: '16px', md: 'full' }}
              src={`/therapists/therapist-${id}.jpeg`}
              alt={therapist.first_name}
            />
            <Box w={{ md: '60%' }} display="flex" alignItems="left" justifyContent="center" flexDir={'column'}>
              <Text pt={{ base: 4, md: 0 }} fontSize={{ base: '2xl', md: '4xl' }} fontWeight="bold">
                {therapist.first_name + ' ' + therapist.last_name}
              </Text>
              <Text>"{therapist.description}"</Text>
            </Box>
            <Box display={{ base: 'none', md: 'flex' }} w={{ md: '50%' }} justifyContent="flex-end">
              <Image
                boxSize={'300px'}
                objectFit="cover"
                borderRadius={{ base: '16px', md: 'full' }}
                src={`/therapists/therapist-${id}.jpeg`}
                alt={therapist.first_name}
              />
            </Box>
          </Box>
        </SoftCard>
      )}

      <Text pt={12} fontSize="xl" fontWeight="bold">
        Lediga tider
      </Text>
      <Box>
        Oavsett din sökorsak finns möjlighet att möta sexolog i enskilt samtal eller tillsammans med din partner.
      </Box>
      <Box display="flex" alignItems="center">
        <FormLabel htmlFor="par-switch" mb="0">
          Visa tider för parsamtal
        </FormLabel>
        <Switch onChange={(e) => setShowCouples(e.target.checked)} id="par-switch" size="lg" color="purple" />
      </Box>
      <Box width="100%">
        {Object.keys(appointments).length > 0 && (
          <AppointmentList handleClick={preview} showCouples={showCouples} appointmentsByDay={appointments} />
        )}
      </Box>
      {previewedAppointment && previewedAppointment.therapist && (
        <ModalDefault
          actionTitle="Boka"
          handleSubmit={() => {
            book(previewedAppointment.id);
            // purchaseProduct(showCouples);
          }}
          onClose={() => {
            setPreviewedAppointment(null);
            setNextAppointmentId(null);
            setFreeCard(false);
          }}
          isOpen={!!previewedAppointment}
        >
          <Box p={4}>
            <Box display="flex" alignItems={'center'} pb={4}>
              <Image
                boxSize="75px"
                objectFit="cover"
                borderRadius="full"
                src={`/therapists/therapist-${previewedAppointment?.therapist?.id}.jpeg`}
                alt={previewedAppointment?.therapist.first_name}
              />
              <Text ml={16} fontSize="xl" fontWeight="bold">
                {previewedAppointment?.therapist.first_name + ' ' + previewedAppointment?.therapist.last_name}
              </Text>
            </Box>
            <Text fontSize="sm" color="gray.600">
              {previewedAppointment?.therapist?.description}
            </Text>
            <Text pt={4} fontSize="md" color="gray.600">
              {formatDateStringWithWeekdayInSwedish(previewedAppointment?.start_time)}
            </Text>
            <Text fontSize="md" color="gray.600">
              {formatTimeString(previewedAppointment?.start_time)}
              {/* {previewedAppointment?.start_time - previewedAppointment?.end_time} */}
            </Text>
            <Text fontSize="md" color="gray.600">
              {calculateMinutesBetweenDates(previewedAppointment?.start_time, previewedAppointment?.end_time)}
            </Text>
            <Box pt={4}>
              <Text>{priceText}</Text>
              <Checkbox pt={2} checked={freeCard} onChange={() => setFreeCard(!freeCard)} colorScheme={'purple'}>
                Jag har frikort
              </Checkbox>
            </Box>
          </Box>
        </ModalDefault>
      )}
    </AuthenticatedLayout>
  );
};

export default TherapistDetailPage;

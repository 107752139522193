export const formatDateString = (dateString: Date): string => {
  let date = new Date(dateString);

  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, '0'); // months are 0-indexed in JS
  let day = date.getDate().toString().padStart(2, '0');
  let hours = date.getHours().toString().padStart(2, '0');
  let minutes = date.getMinutes().toString().padStart(2, '0');

  return `${year}/${month}/${day} ${hours}:${minutes}`;
};

export const formatTimeString = (dateString: Date): string => {
  let date = new Date(dateString);

  let hours = date.getHours().toString().padStart(2, '0');
  let minutes = date.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
};

export const formatWeekdayTimeString = (dateString: Date): string => {
  let date = new Date(dateString);

  let weekdayArray = ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'];
  let weekday = weekdayArray[date.getDay()];
  let hours = date.getHours().toString().padStart(2, '0');
  let minutes = date.getMinutes().toString().padStart(2, '0');

  return `${weekday}, ${hours}:${minutes}`;
};

export const formatDateStringWithWeekdayInSwedish = (dateString: Date): string => {
  let date = new Date(dateString);

  let weekdayArray = ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'];
  let weekday = weekdayArray[date.getDay()];

  let month = (date.getMonth() + 1).toString(); // months are 0-indexed in JS
  let day = date.getDate().toString();

  return `${weekday}, ${day}/${month}`;
};

export const calculateMinutesBetweenDates = (startDate: Date, endDate: Date): string => {
  let start = new Date(startDate);
  let end = new Date(endDate);

  let differenceInMilliseconds = end.getTime() - start.getTime();
  let differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));

  return `${differenceInMinutes} minuter`;
};

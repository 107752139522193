import { useState, useEffect } from 'react';
import { createClient } from 'contentful';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Box,
  Text,
} from '@chakra-ui/react';
import { MdMenu } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { FaArrowRight, FaCog } from 'react-icons/fa';
import { SignOutButton } from '../authentication/SignOutButton';
import { useRecoilValue } from 'recoil';
import { currentUserState } from '../../state';

const SPACE_ID = 'gzob90rstqf1';
const ACCESS_TOKEN = 'MQoWTE2rM5Hn9Vd2BWAEig04tJBZwuQ9LXaDwp6vJyw';

const client = createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN,
});

interface TopicFields {
  title: string;
  description: string;
}

interface Topic {
  sys: {
    id: string;
  };
  fields: TopicFields;
}

const SlideOutMenu = () => {
  const [topics, setTopics] = useState<Topic[]>([]);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    client
      .getEntries({ content_type: 'topic' })
      .then((response) => setTopics(response.items as unknown as Topic[]))
      .catch(console.error);
  }, []);

  const user = useRecoilValue(currentUserState);

  return (
    <>
      <Button
        onClick={() => {
          setMenuOpen(true);
        }}
        ml={2}
        justifyContent={'center'}
        display="flex"
        gap={2}
        borderRadius={'8px'}
        border="2px solid #ECE9FB"
        backgroundColor={'#F6F5FF'}
        color={'#5E13D8'}
        _hover={{
          backgroundColor: '#ECE9FB',
          border: '2px solid #E0DCF9',
          color: '#5112B0',
        }}
        _active={{
          backgroundColor: '#E0DCF9',
          border: '2px solid #E0DCF9',
          color: '#420F94',
        }}
        fontWeight="semibold"
        textColor="#5E13D8"
        alignItems={'center'}
        role="group"
      >
        <MdMenu />
      </Button>

      <Drawer
        placement="right"
        onClose={() => {
          setMenuOpen(false);
        }}
        isOpen={menuOpen}
      >
        <DrawerOverlay>
          <DrawerContent bgColor={'purple.50'} py={12}>
            <DrawerCloseButton />
            <DrawerBody h={'screen'} display="flex" flexDir="column" justifyContent="space-between">
              <Box>
                <DrawerHeader fontSize={'base'} px={0} py={2}>
                  Vanliga ämnen
                </DrawerHeader>
                {topics?.map((topic) => (
                  <Box ml={2} _hover={{ textColor: 'purple.600' }} textColor={'purple.900'} py={2} key={topic.sys.id}>
                    <Link to={`/topics/${topic.sys.id}`}>
                      <Text fontSize={'sm'} display="flex" alignItems="center" gap="1">
                        {topic.fields.title}{' '}
                        <span style={{ color: 'gray' }}>
                          <FaArrowRight />
                        </span>
                      </Text>
                    </Link>
                  </Box>
                ))}
                <DrawerHeader fontSize={'base'} px={0} py={2} pt={4}>
                  Annat
                </DrawerHeader>
                <Box ml={2} _hover={{ textColor: 'purple.600' }} textColor={'purple.900'} py={2}>
                  <Link to={`/about`}>
                    <Text fontSize={'sm'} display="flex" alignItems="center" gap="1">
                      Om oss
                      <span style={{ color: 'gray' }}>
                        <FaArrowRight />
                      </span>
                    </Text>
                  </Link>
                </Box>
                <Box ml={2} _hover={{ textColor: 'purple.600' }} textColor={'purple.900'} py={2}>
                  <Link to={`/work-with-us`}>
                    <Text fontSize={'sm'} display="flex" alignItems="center" gap="1">
                      Jobba med oss
                      <span style={{ color: 'gray' }}>
                        <FaArrowRight />
                      </span>
                    </Text>
                  </Link>
                </Box>
              </Box>

              {user && (
                <Box py={2} display="flex" flexDir="column" justifyContent="flex-end">
                  <SignOutButton>Logga ut</SignOutButton>
                  <Box pt="4" mx="auto">
                    <Link to={`/settings`}>
                      <Text w={'100%'} mx={'auto'} fontSize={'sm'} display="flex" alignItems="center" gap="1">
                        {user?.first_name} {user?.last_name}
                        <span style={{ color: 'gray' }}>
                          <FaCog />
                        </span>
                      </Text>
                    </Link>
                  </Box>
                </Box>
              )}
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default SlideOutMenu;

import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, FormLabel, Heading, Input, Text, Textarea, useToast } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';

import { AuthenticatedLayout } from '../layouts';
import { currentUserState } from '../state';
import { SoftCard } from '../components/generic/SoftCard';
import { useTherapistActions } from '../hooks/therapist-actions';
import { Therapist } from '../types';

export const SettingsPage: React.FC = () => {
  const user = useRecoilValue(currentUserState);
  const [therapist, setTherapist] = useState<Therapist | null>(null);
  const [description, setDescription] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const { getTherapistById, updateTherapistById } = useTherapistActions();

  useEffect(() => {
    if (user && user.user_type === 'therapist') {
      getTherapistById(user.id).then((t) => {
        if (t) {
          setTherapist(t);
          setDescription(t.description);
          setFirstName(t.first_name);
          setLastName(t.last_name);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const toast = useToast();

  const onSubmit = async () => {
    try {
      if (description.length > 1000) {
        setError('Beskrivningen är för lång. Max 1000 karaktärer.');
        return;
      }
      if (therapist) {
        const res = await updateTherapistById(therapist.id, {
          description,
          first_name: firstName,
          last_name: lastName,
        });
        if (res) {
          setTherapist(res);
          toast({
            title: 'Profil uppdaterad',
            status: 'success',
            duration: 1500,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      toast({
        title: 'Något gick fel',
        description: 'Unable to update profile',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const hasChanges = () => {
    if (therapist) {
      return (
        therapist.description !== description || therapist.first_name !== firstName || therapist.last_name !== lastName
      );
    }
    return false;
  };

  return (
    <AuthenticatedLayout sidebar={false}>
      <SoftCard>
        <Heading>Profil</Heading>
        {user && (
          <Box width={{ md: 720 }}>
            {therapist ? (
              <>
                <FormControl mt={4}>
                  <FormLabel>Förnamn</FormLabel>
                  <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Efternamn</FormLabel>
                  <Input value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Beskrivning</FormLabel>
                  <Textarea onChange={(e) => setDescription(e.target.value)} value={description}></Textarea>
                  <Button mt={2} colorScheme="purple" onClick={onSubmit} isDisabled={!hasChanges()}>
                    Uppdatera
                  </Button>
                  {error && <Text color="red">{error}</Text>}
                </FormControl>
              </>
            ) : (
              <Box mt={4}>
                <Text fontWeight={700}>Name</Text>
                <Text size="md">
                  {user.first_name} {user.last_name}
                </Text>
              </Box>
            )}
          </Box>
        )}
      </SoftCard>
      <Box display="flex" justifyContent="end" mt={12}>
        <Box textAlign="right">
          <Text fontWeight={700}>Behöver du support?</Text>
          <Text noOfLines={2}>info@charliecare.se</Text>
        </Box>
      </Box>
    </AuthenticatedLayout>
  );
};

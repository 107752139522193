import { useState } from 'react';
import { Box, Input, Button, FormControl, VStack, Image, Text } from '@chakra-ui/react';
import { SoftCard } from '../generic/SoftCard';
import { FaCheck, FaMailBulk } from 'react-icons/fa';

const NewsLetter = () => {
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // try {
    setSubmitted(true);
    await fetch('https://charliecare.us21.list-manage.com/subscribe/post?u=acbe4f17ac9d95db74baf7fa7&id=3118d9cd53', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        EMAIL: email,
        b_acbe4f17ac9d95db74baf7fa7_3118d9cd53: '', // This field should remain empty to avoid bot signups
      }).toString(),
    });
  };

  const validateEmail = (email: string) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  return (
    <Box w={'full'} display={'flex'} flexDirection={{ base: 'column', md: 'row' }} gap={2}>
      <Box borderRadius={'2xl'} boxShadow={'2xl'} w={{ base: '100%', md: '40%' }} h={'auto'}>
        <Image borderRadius={'2xl'} src="/newsletter.jpeg" objectFit="cover" w="100%" h="100%" />
      </Box>

      <SoftCard boxShadow={'2xl'} w={{ base: '100%', md: '60%' }}>
        <VStack spacing={4} alignItems="start">
          <Text textColor={'#24084F'} fontSize="2xl" fontWeight="bold">
            Prenumerera på CharlieCares nyhetsbrev
          </Text>

          <Box w={'full'} as="form" onSubmit={handleSubmit}>
            <FormControl isRequired>
              <Input
                mb={4}
                type="email"
                id="email"
                value={email}
                isDisabled={submitted}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setValidEmail(validateEmail(e.target.value));
                }}
                placeholder="Din email"
                bg="white"
              />
            </FormControl>
            <Button
              textColor="white"
              backgroundColor={'#5E13D8'}
              w="full"
              py={6}
              isDisabled={submitted || !validEmail}
              colorScheme={'purple'}
              fontWeight={'light'}
              type="submit"
            >
              {submitted ? (
                <>
                  <Box mr={2} as="span">
                    Prenumererat
                  </Box>
                  <FaCheck />
                </>
              ) : (
                <>
                  <Box mr={2} as="span">
                    Prenumerera
                  </Box>
                  <FaMailBulk />
                </>
              )}
            </Button>
          </Box>
        </VStack>
      </SoftCard>
    </Box>
  );
};

export default NewsLetter;

import React, { useEffect, useRef, useState } from 'react';

import { AuthenticatedLayout } from '../layouts';
import { useParams } from 'react-router-dom';

import { useAppointmentActions } from '../hooks';
import { Appointment } from '../types';
import { useRecoilValue } from 'recoil';
import { currentUserState } from '../state';

declare global {
  interface Window {
    DailyIframe: any;
  }
}

const DailyComponent = ({ roomUrl = '', firstName = '' }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://unpkg.com/@daily-co/daily-js';
    script.async = true;
    script.onload = () => {
      const callFrame = window.DailyIframe.createFrame(containerRef.current, {
        showLeaveButton: true,
      });
      callFrame.join({ url: roomUrl, userName: firstName });

      return () => {
        if (callFrame) {
          callFrame.destroy();
        }
      };
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [roomUrl, firstName]);

  return (
    <div
      ref={containerRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999,
      }}
    />
  );
};

export default DailyComponent;

export const MeetingPage: React.FC = () => {
  const { id } = useParams();
  const { getAppointmentById } = useAppointmentActions();
  const [appointment, setAppointment] = useState<Appointment | null>(null);

  useEffect(() => {
    const doIt = async () => {
      if (id) {
        const res = await getAppointmentById(id);
        if (res) {
          setAppointment(res);
        }
      }
    };
    doIt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const user = useRecoilValue(currentUserState);

  //   const book = async (appointmentId: string) => {
  //     await bookAppointment(appointmentId);
  //     doIt();
  //   };

  return (
    <AuthenticatedLayout sidebar={false}>
      {appointment && appointment.daily_co_room_url && (
        <DailyComponent firstName={user?.first_name} roomUrl={appointment?.daily_co_room_url}></DailyComponent>
      )}
    </AuthenticatedLayout>
  );
};

import { useState, useEffect } from 'react';
import { createClient } from 'contentful';
import { Link } from 'react-router-dom';
import { Box, Button, Heading, Image, SimpleGrid, Text } from '@chakra-ui/react';
import { SoftCard } from '../generic/SoftCard';
import { FaArrowRight } from 'react-icons/fa';

const SPACE_ID = 'gzob90rstqf1';
const ACCESS_TOKEN = 'MQoWTE2rM5Hn9Vd2BWAEig04tJBZwuQ9LXaDwp6vJyw';

const client = createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN,
});

interface TopicFields {
  title: string;
  description: string;
  icon: any;
}

interface Topic {
  sys: {
    id: string;
  };
  fields: TopicFields;
}

function TopicsList() {
  const [topics, setTopics] = useState<Topic[]>([]);

  useEffect(() => {
    client
      .getEntries({ content_type: 'topic' })
      .then((response) => setTopics(response.items as unknown as Topic[]))
      .catch(console.error);
  }, []);

  return (
    <div>
      <SimpleGrid minChildWidth="300px" width="100%" spacingX="20px" spacingY="20px">
        {topics.map((topic) => {
          return (
            <SoftCard
              cursor="pointer"
              key={topic.sys.id}
              style={{
                minHeight: '140px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
              textAlign="center"
            >
              <Box>
                <Box pb={4} display="flex" justifyContent={'center'}>
                  <Box p={4} borderRadius={'100%'} backgroundColor={'#ECE9FB'}>
                    <Image h={'48px'} src={topic.fields.icon?.fields?.file?.url} alt="Icon" />
                  </Box>
                </Box>
                <Heading textAlign={'center'} textColor="#24084F" size="md" pb={4} alignItems="center">
                  {topic.fields.title}
                </Heading>
                <Text textColor="#24084F">{topic.fields.description}</Text>
              </Box>
              <Link to={'/topics/' + topic.sys.id}>
                <Button
                  mt={4}
                  justifyContent={'center'}
                  display="flex"
                  gap={2}
                  width={'100%'}
                  borderRadius={'8px'}
                  border="2px solid #ECE9FB"
                  backgroundColor={'purple.50'}
                  _hover={{
                    backgroundColor: 'purple.100',
                  }}
                  fontWeight="semibold"
                  textColor="#5E13D8"
                  alignItems={'center'}
                >
                  <Text>Läs mer</Text>
                  <FaArrowRight />
                </Button>
              </Link>
            </SoftCard>
          );
        })}
      </SimpleGrid>
    </div>
  );
}

export default TopicsList;

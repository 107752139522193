import React from 'react';
import TopicsList from '../components/topics/TopicsList';

import { AuthenticatedLayout } from '../layouts';

const TopicsPage: React.FC = () => {
  return (
    <AuthenticatedLayout sidebar={false}>
      <TopicsList />
    </AuthenticatedLayout>
  );
};

export default TopicsPage;

import { Text, Box, Heading, Image } from '@chakra-ui/react';
import React from 'react';
import { SoftCard } from '../components/generic/SoftCard';
import { AuthenticatedLayout, UnAuthenticatedLayout } from '../layouts';

type SomeComponentProps = {
  loggedIn: boolean;
};

const Content = () => (
  <SoftCard textColor="#24084F" display={'flex'} flexDirection={'column'} gap={4} maxW={'700px'}>
    <Box mx={'auto'} w={{ base: '100%' }}>
      <Image borderRadius={'24px'} width="100%" height="100%" objectFit="cover" src="/founders.jpeg" />
    </Box>
    <Heading textAlign={'center'} textColor="24084F">
      Om oss
    </Heading>
    <Text pt={2}>
      Charlie Care AB grundades år 2023 av Ulrika Linnersjö och Ninni Stålhammar med stark önskan att erbjuda
      professionell vård inom sexuell hälsa.
    </Text>
    <Text fontWeight="bold" fontSize="xl" pt={2}>
      Varför välja CharlieCare?
    </Text>
    <Text>
      Många lider av sexuella besvär i tystnad pga. att ämnet är stigmatiserat och där känslor som skuld och skam är
      vanliga. Det finns ett stort mörkertal inom sexuell hälsa där CharlieCares huvudsakliga uppgift är att vara
      tillgänglig för alla oavsett vilka besvär du har eller var i landet du än bor.
    </Text>
    <Text pt={2}>
      Genom att erbjuda digital sexologisk rådgivning ges du möjlighet att själv välja en trygg plats för att samtala
      kring dina besvär. Du väljer själv om du önskar delta via videobesök eller telefonsamtal. Dina behov och önskemål
      är det viktigaste i det digitala rådgivningssamtalet med sexolog.
    </Text>
    <Text pt={2}>
      Alla våra sexologer är utbildade inom sexuell hälsa och alla sexologer har lång erfarenhet och gedigen kunskap av
      att samtala kring sexuella besvär.
    </Text>
    <Text fontWeight="bold" fontSize="xl" pt={2}>
      Våra grundare
    </Text>
    <Text pt={2}>
      Vi som grundat CharlieCare har lång erfarenhet inom digital vård där vi tydligt identifierat ett stort behov av
      att kunna få professionell sexologisk rådgivning digitalt. Vi brinner för allas rätt till att få professionell
      sexologisk rådgivning när sex känns svårt.
    </Text>
    <Box pt={2}>
      <Text>
        <strong>Ulrika Linnersjö</strong>
      </Text>
      <Text>VD</Text>
    </Box>
    <Box pt={2}>
      <Text>
        <strong>Ninni Stålhammar</strong>
      </Text>
      <Text>Verksamhetschef, barnmorska och sexolog</Text>
    </Box>
  </SoftCard>
);

const AboutUsPage: React.FC<SomeComponentProps> = ({ loggedIn }) => {
  return loggedIn ? (
    <AuthenticatedLayout>
      <Content />
    </AuthenticatedLayout>
  ) : (
    <UnAuthenticatedLayout sidebar={false}>
      <Content />
    </UnAuthenticatedLayout>
  );
};

export default AboutUsPage;

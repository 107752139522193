import React, { useEffect, useState } from 'react';
import { Text, VStack, Spinner, Box } from '@chakra-ui/react';

import { AuthenticatedLayout } from '../layouts';
import { useRecoilValue } from 'recoil';
import { currentUserState } from '../state';
import { useAppointmentActions } from '../hooks';
import { SoftCard } from '../components/generic/SoftCard';
import { Appointments } from '../types';
import { AppointmentList } from '../components/appointments/AppointmentList';
import CreateAppointments from '../components/appointments/CreateAppointments';

export const AppointmentsPage: React.FC = () => {
  const user = useRecoilValue(currentUserState);
  // const { getTherapists } = useTherapistActions();

  const { getAppointmentsByTherapistId, getMyUpcomingAppointments, deleteAppointment } = useAppointmentActions();
  // const [therapists, setTherapists] = useState<Therapist[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [appointments, setAppointments] = useState<Appointments>([]);
  const [bookedAppointments, setBookedAppointments] = useState<Appointments>([]);

  const doIt = async () => {
    setLoading(true);
    // const allTherapists = await getTherapists();
    if (user) {
      const allAppointments = await getAppointmentsByTherapistId(user?.id);
      const upcomingAppointments = await getMyUpcomingAppointments();
      // if (allTherapists) {
      //   setTherapists(allTherapists);
      // }
      if (allAppointments) {
        setAppointments(allAppointments);
      }
      if (upcomingAppointments) {
        setBookedAppointments(upcomingAppointments);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    doIt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const book = async (appointmentId: string) => {
    await deleteAppointment(appointmentId);
    // await bookAppointment(appointmentId);
    if (user) {
      const allAppointments = await getAppointmentsByTherapistId(user.id);
      if (allAppointments) {
        setAppointments(allAppointments);
      }
    }
    // doIt();
  };

  return (
    <AuthenticatedLayout sidebar={false}>
      {user?.user_type === 'therapist' ? (
        <VStack spacing={12}>
          {Object.keys(bookedAppointments).length > 0 ? (
            <Box w={'100%'}>
              <Text textAlign={'center'} pb={4}>
                Dina uppkommande möten
              </Text>
              <AppointmentList therapist handleClick={book} upcoming appointmentsByDay={bookedAppointments} />
            </Box>
          ) : (
            <>
              {loading ? (
                <SoftCard>
                  <Spinner mx={'auto'} />
                </SoftCard>
              ) : (
                <SoftCard>Inga kommande möten</SoftCard>
              )}
            </>
          )}
          <CreateAppointments handleUpdate={doIt} />
          <Box>
            <Text py={4}>Dina lediga tider:</Text>
            <Text color={'gray'}>Klicka på en av tiderna för att radera den</Text>
            <AppointmentList therapist handleClick={book} appointmentsByDay={appointments} />
          </Box>
        </VStack>
      ) : (
        <Box></Box>
      )}
    </AuthenticatedLayout>
  );
};

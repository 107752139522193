import { useCallback } from 'react';
import { ApiClient } from '../lib/clients/api-client';
import { StripeSession } from '../types';
import { useApiActions } from './api-actions';
import { useAppointmentActions } from './appointment-actions';

export const usePaymentActions = () => {
  const { executeApiAction } = useApiActions();
  const { bookAppointment } = useAppointmentActions();

  const purchaseProduct = async (
    couples: boolean = false,
    appointmentId: string,
    secondAppointmentId: string | null = null,
    freeCard: boolean = false
  ) => {
    let productId = couples ? 'price_1PRJSaF3aWugatekC9oI7df9' : 'price_1PRJEqF3aWugatek6RR9FnbD';
    // prod real prices^^

    if (freeCard && couples) {
      productId = 'price_1PRJSkF3aWugatekzYClsKKA';
    }

    if (freeCard && !couples) {
      bookAppointment(appointmentId);
    } else {
      // const productId = couples ? 'price_1O7JfgF3aWugatekvkeckEVY' : 'price_1O7JezF3aWugatek5vgoYocg';
      // prod old prices^^ (not used anymore)
      // const productId = couples ? 'price_1NfJJ0F3aWugatekoqOXoSNk' : 'price_1Nxy9IF3aWugatekug5GA6Ef';
      // prod test prices^^
      // const productId = couples ? 'price_1NdUefF3aWugatek13Xe22wz' : 'price_1NdCxsF3aWugatek6N2uHJ4c';
      // local prices^
      return await executeApiAction<StripeSession>({
        action: () => ApiClient.createStripeSession({ productId, appointmentId, secondAppointmentId, freeCard }),
        onSuccess: (session: StripeSession) => window.location.replace(session.url),
        errorMessage:
          'Failed to create Stripe session. Make sure you have configured your Stripe API keys for this environment.',
      });
    }
  };

  return {
    purchaseProduct: useCallback(purchaseProduct, [executeApiAction, bookAppointment]),
  };
};

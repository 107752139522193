import { Box, Heading, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { SoftCard } from '../components/generic/SoftCard';

import { AuthenticatedLayout, UnAuthenticatedLayout } from '../layouts';

type SomeComponentProps = {
  loggedIn: boolean;
};

const Content = () => (
  <SoftCard textColor="#24084F" display={'flex'} flexDirection={'column'} gap={4} maxW={'700px'}>
    <Box mx={'auto'} w={{ base: '100%' }}>
      <Image borderRadius={'24px'} width="100%" height="100%" objectFit="cover" src="/team.jpeg" />
    </Box>
    <Heading textAlign={'center'} textColor="24084F">
      Jobba med oss
    </Heading>
    <Text>CharlieCare är i ett expansivt skede där vi söker dig som vill vara med på vår tillväxtresa.</Text>
    <Text pt={4}>
      Är du intresserad av att arbeta tillsammans med oss är du alltid välkommen att mejla ditt personliga brev och CV
      till
    </Text>
    <Text pt={4}>info@charliecare.se</Text>
  </SoftCard>
);

const WorkWithUsPage: React.FC<SomeComponentProps> = ({ loggedIn }) => {
  return loggedIn ? (
    <AuthenticatedLayout>
      <Content />
    </AuthenticatedLayout>
  ) : (
    <UnAuthenticatedLayout sidebar={false}>
      <Content />
    </UnAuthenticatedLayout>
  );
};

export default WorkWithUsPage;

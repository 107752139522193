import { AspectRatio, Box, Button, Image, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Therapist } from '../../types';

export const TherapistCard = ({ therapist, notLoggedIn }: { therapist: Therapist; notLoggedIn?: boolean }) => {
  const background = useColorModeValue('white', 'gray.700');
  const backgroundHover = useColorModeValue('gray.100', 'gray.800');

  return notLoggedIn ? (
    <Box mx="auto" w="full" maxW="3xs" overflow="hidden">
      <AspectRatio ratio={1 / 1}>
        <Image
          borderRadius="full"
          objectPosition="center"
          src={`/therapists/therapist-${therapist.id}.jpeg`}
          alt={therapist.first_name}
        />
      </AspectRatio>

      <Box textAlign="center" mt="2" fontWeight="semibold" as="h6" lineHeight="tight" noOfLines={1}>
        {therapist.first_name + ' ' + therapist.last_name}
      </Box>

      <Box>
        {/* Add relevant information here */}
        <Box as="span" color="gray.600" fontSize="sm">
          {/* Add relevant information here */}
        </Box>
      </Box>
    </Box>
  ) : (
    <Link to={`/therapists/${therapist.id}`}>
      <Box
        background={background}
        _hover={{ background: backgroundHover }}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
      >
        <AspectRatio ratio={16 / 9}>
          <Image
            objectPosition="center"
            src={`/therapists/therapist-${therapist.id}.jpeg`}
            alt={therapist.first_name}
          />
        </AspectRatio>

        <Box p="6">
          <Box display="flex" alignItems="baseline">
            <Box
              color="gray.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
              ml="2"
            >
              {/* Par, Enkel */}
            </Box>
          </Box>

          <Box mt="1" fontWeight="semibold" as="h6" lineHeight="tight" noOfLines={1}>
            {therapist.first_name + ' ' + therapist.last_name}
          </Box>

          <Box>
            {/* Add relevant information here */}
            <Box as="span" color="gray.600" fontSize="sm">
              {/* Add relevant information here */}
            </Box>
          </Box>

          <Box display="flex" mt="2" alignItems="center">
            {therapist?.description?.slice(0, 100) || ''}...
          </Box>
          <Box display="flex" mt="2" justifyContent="space-between" alignItems={'center'}>
            <Link to={`/therapists/${therapist.id}`}>Läs mer</Link>
            <Link to={`/therapists/${therapist.id}`}>
              <Button>Boka tid</Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

import { useCallback } from 'react';
import { ApiClient, UpdateTherapistOptions } from '../lib/clients/api-client';
import { Therapist } from '../types';
import { useApiActions } from './api-actions';

export const useTherapistActions = () => {
  const { executeApiAction } = useApiActions();

  const getTherapists = () =>
    executeApiAction<Therapist[]>({
      action: async () => {
        const { therapists } = await ApiClient.getTherapists();
        return therapists;
      },
    });

  const getTherapistById = (id: string) =>
    executeApiAction<Therapist>({
      action: async () => {
        const { therapist } = await ApiClient.getTherapistById(id);
        return therapist;
      },
    });

  const updateTherapistById = useCallback(
    (id: string, options: UpdateTherapistOptions) => {
      return executeApiAction<Therapist>({
        action: async () => {
          const { therapist } = await ApiClient.updateTherapistById(id, options);
          return therapist;
        },
        onError: (e: Error) => console.log('error updating therapist', e),
        errorMessage: 'Det gick inte att uppdatera',
      });
    },
    [executeApiAction]
  );

  return {
    getTherapists,
    getTherapistById,
    updateTherapistById,
  };
};

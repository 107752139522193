import React, { useEffect, useState } from 'react';
import { SimpleGrid, Box } from '@chakra-ui/react';

import { AuthenticatedLayout } from '../layouts';
import { Therapist } from '../types';
import { useTherapistActions } from '../hooks/therapist-actions';

import { TherapistCard } from '../components/therapists/TherapistCard';

export const TherapistsPage: React.FC = () => {
  const { getTherapists } = useTherapistActions();

  const [therapists, setTherapists] = useState<Therapist[]>([]);

  useEffect(() => {
    const doIt = async () => {
      const allTherapists = await getTherapists();
      if (allTherapists) {
        setTherapists(allTherapists);
      }
    };
    doIt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthenticatedLayout sidebar={false}>
      <Box>
        <SimpleGrid columns={{ md: 2 }} spacing={10}>
          {therapists?.map((therapist) => (
            <TherapistCard key={therapist.id} therapist={therapist} />
          ))}
        </SimpleGrid>
      </Box>
    </AuthenticatedLayout>
  );
};

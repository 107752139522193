import * as React from 'react';
import { Button, Card, useColorModeValue } from '@chakra-ui/react';

export const SoftCard = ({
  children,
  p,
  button = false,
  onClick,
  ...rest
}: {
  children?: React.ReactNode;
  onClick?: (...args: any[]) => void;
  [x: string]: any;
}) => {
  const background = useColorModeValue('white', 'gray.700');
  const backgroundHover = useColorModeValue('gray.100', 'gray.800');

  return button ? (
    <Button
      onClick={onClick}
      transition="all 0.2s"
      _hover={onClick ? { background: backgroundHover } : {}}
      background={background}
      w={'100%'}
      p={
        p || {
          base: 4,
          md: 6,
        }
      }
      boxShadow="none"
      borderRadius="2xl"
      {...rest}
    >
      {children}
    </Button>
  ) : (
    <Card
      onClick={onClick}
      transition="all 0.2s"
      _hover={onClick ? { background: backgroundHover } : {}}
      background={background}
      w={'100%'}
      p={
        p || {
          base: 4,
          md: 8,
        }
      }
      boxShadow="purple"
      borderRadius="2xl"
      {...rest}
    >
      {children}
    </Card>
  );
};

import { useCallback } from 'react';
import { ApiClient, CreateAppointmentsOptions } from '../lib/clients/api-client';
import { Appointment, Appointments } from '../types';
import { useApiActions } from './api-actions';

export const useAppointmentActions = () => {
  const { executeApiAction } = useApiActions();

  const getAppointments = useCallback(() => {
    return executeApiAction<Appointments>({
      action: async () => {
        const { appointments } = await ApiClient.getAppointments();
        return appointments;
      },
      onError: (e: Error) => console.log('error retrieving appointments', e),
      errorMessage: 'Failed to load project, refresh to try again',
    });
  }, [executeApiAction]);

  const getAppointmentById = useCallback(
    (id: string) => {
      return executeApiAction<Appointment>({
        action: async () => {
          const { appointment } = await ApiClient.getAppointmentById(id);
          return appointment;
        },
        onError: () => console.log('error retrieving appointments'),
        errorMessage: 'Failed to load project, refresh to try again',
      });
    },
    [executeApiAction]
  );
  const previewAppointmentById = useCallback(
    (id: string) => {
      return executeApiAction<Appointment>({
        action: async () => {
          const { appointment } = await ApiClient.previewAppointmentById(id);
          return appointment;
        },
        onError: () => console.log('error retrieving appointments'),
        errorMessage: 'Failed to load project, refresh to try again',
      });
    },
    [executeApiAction]
  );

  const previewCouplesAppointmentById = useCallback(
    (id: string, nextId: string) => {
      return executeApiAction<Appointment>({
        action: async () => {
          const { appointment } = await ApiClient.previewCouplesAppointmentById(id, nextId);
          return appointment;
        },
        onError: () => console.log('error retrieving appointments for couples'),
        errorMessage: 'Failed to load project for couples, refresh to try again',
      });
    },
    [executeApiAction]
  );

  const getAppointmentsByTherapistId = useCallback(
    (id: string) => {
      return executeApiAction<Appointments>({
        action: async () => {
          const { appointments } = await ApiClient.getAppointmentsByTherapist(id);
          return appointments;
        },
        onError: () => console.log('error retrieving appointments'),
        errorMessage: 'Failed to load project, refresh to try again',
      });
    },
    [executeApiAction]
  );

  const getMyUpcomingAppointments = useCallback(() => {
    return executeApiAction<Appointments>({
      action: async () => {
        const { appointments } = await ApiClient.getMyUpcomingAppointments();
        return appointments;
      },
      onError: () => console.log('error retrieving appointments'),
      errorMessage: 'Failed to load project, refresh to try again',
    });
  }, [executeApiAction]);

  const bookAppointment = useCallback(
    (appointmentId: string) => {
      return executeApiAction<Appointment>({
        action: async () => {
          const { appointment } = await ApiClient.bookAppointment(appointmentId);
          return appointment;
        },
        onError: () => console.log('error booking appointment'),
        errorMessage: 'Failed to book appointment, refresh to try again',
      });
    },
    [executeApiAction]
  );

  const deleteAppointment = useCallback(
    (appointmentId: string) => {
      return executeApiAction<Appointment>({
        action: async () => {
          const { message } = await ApiClient.deleteSingleAppointment(appointmentId);
          return message;
        },
        onError: () => console.log('error deleting appointment'),
        errorMessage: 'Failed to delete appointment, refresh to try again',
      });
    },
    [executeApiAction]
  );
  const createAppointments = useCallback(
    (options: CreateAppointmentsOptions) => {
      return executeApiAction<Appointments>({
        action: async () => {
          const { appointments } = await ApiClient.createAppointments(options);
          return appointments;
        },
        onError: (e: Error) => console.log('error creating appointments', e),
        errorMessage: 'Failed to create appointments, refresh to try again',
      });
    },
    [executeApiAction]
  );

  return {
    getAppointments,
    getAppointmentById,
    previewAppointmentById,
    previewCouplesAppointmentById,
    getMyUpcomingAppointments,
    bookAppointment,
    deleteAppointment,
    getAppointmentsByTherapistId,
    createAppointments,
  };
};

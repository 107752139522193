import React, { useState, useEffect } from 'react';
import { createClient } from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useParams } from 'react-router-dom';
import { Box, Heading, Image, Spinner } from '@chakra-ui/react';

const SPACE_ID = 'gzob90rstqf1';
const ACCESS_TOKEN = 'MQoWTE2rM5Hn9Vd2BWAEig04tJBZwuQ9LXaDwp6vJyw';

const client = createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN,
});

interface TopicFields {
  title: string;
  content: any;
  icon: any;
}

interface Topic {
  fields: TopicFields;
}

const TopicDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [topic, setTopic] = useState<Topic | null>(null);

  useEffect(() => {
    if (!id) return;
    client
      .getEntry(id)
      .then((response) => setTopic(response as unknown as Topic))
      .catch(console.error);
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!topic)
    return (
      <Box display="flex" justifyContent={'center'}>
        <Spinner mx="auto" />
      </Box>
    );

  return (
    <Box py={12} display={'flex'} flexDirection={'column'} gap={4} maxW={'700px'}>
      <Box pb={2} display="flex" justifyContent={'center'}>
        <Box p={4} borderRadius={'100%'} backgroundColor={'#ECE9FB'}>
          <Image h={'72px'} src={topic.fields.icon?.fields?.file?.url} alt="Icon" />
        </Box>
      </Box>
      <Heading textAlign={'center'} fontSize={'2xl'}>
        {topic.fields.title}
      </Heading>
      {documentToReactComponents(topic.fields.content)}
    </Box>
  );
};

export default TopicDetail;

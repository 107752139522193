import '@fontsource/geologica';

export const globalStyles = {
  shadows: {
    purple: '0px 4px 24px 0px rgba(20, 4, 44, 0.04)',
  },
  colors: {
    colors: {
      gray: {
        700: '#1f2733',
      },
      brown: {
        100: '#ede7e1',
      },
      purple: {
        50: '#f6f5ff',
        100: '#ece9fb',
        200: '#e0dcf9',
        300: '#cbc2f5',
        400: '#b6a8f0',
        500: '#a08be5',
        600: '#8665e2',
        700: '#7540e7',
        800: '#5e13d8',
        900: '#14042c',
      },
      desaturated: {
        50: '#ffffff',
        100: '#fafafa',
        200: '#f2f1f3',
        300: '#eae9ec',
        400: '#dad9de',
        500: '#cac8d0',
        600: '#b5b3bd',
        700: '#a3a0ac',
        800: '#8e8a99',
        900: '#17151b',
      },
    },
  },
  fonts: {
    body: '"Geologica", sans-serif',
    heading: '"Geologica", sans-serif',
  },
  fontWeights: {
    body: 200,
    heading: 440,
    bold: 700,
  },
  lineHeights: {
    body: 1.12,
    heading: 1.12,
  },
  letterSpacings: {
    body: '-0.72px',
  },
  textStyles: {
    body: {
      fontFamily: '"Geologica", sans-serif',
      fontSize: '1rem',
      fontWeight: 200,
      // lineHeight: 1.12,
      fontVariantNumeric: 'lining-nums tabular-nums stacked-fractions ordinal',
      fontFeatureSettings: '"rvrn" on',
      fontStyle: 'normal',
      fontVariationSettings: '"SHRP" 0',
      letterSpacing: '-0.72px',
      color: 'text.dark',
    },
    heading: {
      fontFamily: '"Geologica", sans-serif',
      fontWeight: 440,
      fontVariationSettings: '"SHRP" 24',
      // lineHeight: 1.12,
      // marginBottom: 4,
    },
  },
  styles: {
    global: (props: any) => ({
      body: {
        bg: 'purple.50',
        color: 'text.dark',
      },
      'html, body, #root': {
        minHeight: '100vh',
      },
      h1: {
        fontSize: ['3.2rem', '4.8rem', '5.6rem'],
        marginBottom: '0.75rem',
      },
      h2: {
        fontSize: ['2.8rem', '3.2rem', '4rem'],
        // marginBottom: '0.75rem',
      },
      h3: {
        fontSize: ['2.4rem', '2.6rem', '2.8rem'],
        // marginBottom: '0.5rem',
      },
      h4: {
        fontSize: ['1.7rem', '1.85rem', '2rem'],
        // marginBottom: '0.25rem',
      },
      h5: {
        fontSize: ['1.2rem', '1.3rem', '1.4rem'],
        // marginBottom: '0.25rem',
      },
      h6: {
        fontSize: ['1rem', '1rem', '1.25rem'],
        // marginBottom: '0.25rem',
      },
      p: {
        // marginBottom: '1.5rem',
      },
    }),
  },
};

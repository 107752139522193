import { Button } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { ReactNode } from 'react';

export const ModalDefault = ({
  children,
  isOpen,
  onClose,
  actionTitle,
  handleSubmit,
  hideFooter = false,
}: {
  children: ReactNode;
  isOpen: boolean;
  onClose: any;
  actionTitle: string;
  handleSubmit?: any;
  hideFooter?: boolean;
}) => {
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(2px) rotate(90deg)" />{' '}
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>

        {!hideFooter && (
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Stäng
            </Button>
            <Button onClick={handleSubmit}>{actionTitle}</Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
